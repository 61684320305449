<template>
    <modal ref="modalOpcionesProductos" titulo="Opciones de los productos" tamano="modal-lg" no-aceptar no-cancelar>
        <div class="row mx-0">
            <div class="col">
                <div class="row mx-0 justify-center mb-5">
                    <p class="col-12 text-center text-general f-18 mb-3">
                        Crear masivamente productos
                    </p>
                    <div class="col-auto">
                        <div class="row mx-0 justify-center">
                            <div class="box-green text-white">
                                <vue-excel-xlsx
                                class="btn"
                                :columns="fieldsPlantilla"
                                :filename="'plantilla_productos'"
                                :sheetname="'sheetname'"
                                :data="examplePlantilla"
                                >
                                    <i class="icon-dowload-arrow f-26 text-white" />
                                </vue-excel-xlsx>
                            </div>
                        </div>
                        <p class="text-grey f-14 text-center mt-2">
                            Descargar plantilla
                        </p>
                    </div>
                    <div class="col-auto">
                        <div class="row mx-0 justify-center">
                            <div class="box-general text-white cr-pointer" @click="setImportarProductos">
                                <i class="icon-importar f-18" />
                            </div>
                        </div>
                        <p class="text-grey f-14 text-center mt-2">
                            Importar productos
                        </p>
                    </div>
                </div>
                <div class="row mx-0 justify-center mb-5">
                    <p class="col-12 text-center text-general f-18 mb-3">
                        Actualizar datos
                    </p>
                    <div class="col-auto">
                        <div class="row mx-0 justify-center">
                            <div class="box-green text-white">
                                <vue-excel-xlsx
                                class="btn"
                                :columns="fieldsPlantillaUpdateData"
                                :filename="'plantilla_datos'"
                                :sheetname="'sheetname'"
                                :data="plantillaUpdateData"
                                >
                                    <i class="icon-dowload-arrow  text-white f-26" />
                                </vue-excel-xlsx>
                            </div>
                        </div>
                        <p class="text-grey f-14 text-center mt-2">
                            Descargar plantilla
                        </p>
                    </div>
                    <div class="col-auto">
                        <div class="row mx-0 justify-center">
                            <div class="box-general text-white" @click="importUpdateData">
                                <i class="icon-importar f-18" />
                            </div>
                        </div>
                        <p class="text-grey f-14 text-center mt-2">
                            Importar actualización
                        </p>
                    </div>
                </div>
                <!-- <div class="row mx-0 justify-center">
                    <p class="col-12 text-center text-general f-18 mb-3">
                        Actualizar precios
                    </p>
                    <div class="col-auto">
                        <div class="row mx-0 justify-center">
                            <div class="box-green text-white">
                                <i class="icon-dowload-arrow f-26" />
                            </div>
                        </div>
                        <p class="text-grey f-14 text-center mt-2">
                            Descargar plantilla
                        </p>
                    </div>
                    <div class="col-auto">
                        <div class="row mx-0 justify-center">
                            <div class="box-general text-white">
                                <i class="icon-importar f-18" />
                            </div>
                        </div>
                        <p class="text-grey f-14 text-center mt-2">
                            Importar actualización
                        </p>
                    </div>
                </div> -->
            </div>
            <div class="col">
                <div v-if="$can('boton_productos_descargar_catalogo')" class="row mx-0 justify-center mb-5">
                    <p class="col-12 text-center text-general f-18 mb-3">
                        Catalogo de productos
                    </p>
                    <div class="col-auto">
                        <div class="row mx-0 justify-center">
                            <div class="box-green text-white" @click="descargarCatalogo">
                                <i class="icon-dowload-arrow f-26" />
                            </div>
                        </div>
                        <p class="text-grey f-14 text-center mt-2">
                            Descargar catalogo (PDF)
                        </p>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <p class="col-12 text-center text-general f-18 mb-3">
                        Actualizar inventario
                    </p>
                    <div class="col-auto">
                        <div class="row mx-0 justify-center">
                            <div class="box-green text-white">
                                <vue-excel-xlsx
                                class="btn"
                                :data="examplePlantilla"
                                :columns="fieldsPlantillaInventario"
                                :filename="'plantilla_stock'"
                                :sheetname="'sheetname'"
                                >
                                    <i class="icon-dowload-arrow text-white f-26" />
                                </vue-excel-xlsx>
                            </div>
                        </div>
                        <p class="text-grey f-14 text-center mt-2">
                            Descargar plantilla
                        </p>
                    </div>
                    <div v-if="$can('boton_productos_importar_stock')" class="col-auto">
                        <div class="row mx-0 justify-center">
                            <div class="box-general text-white" @click="uploadInventario">
                                <i class="icon-importar f-18" />
                            </div>
                        </div>
                        <p class="text-grey f-14 text-center mt-2">
                            Importar inventario
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import VueExcelXlsx from "vue-excel-xlsx";
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)
Vue.use(VueExcelXlsx)
export default {
    data(){
        return {
            examplePlantilla:[{barcode:'',cantidad:'',valor:'',proveedor:'',comentario:''}],
            plantillaUpdateData:[{codigo:''}]
        }
    },
    computed:{
        fieldsPlantilla(){
            return[
                {
                    label: "Barcode",
                    field: "barcode",
                },
                {
                    label: "Sku",
                    field: "sku",
                },
                {
                    label: "Nombre Producto",
                    field: "nombre",
                },
                {
                    label: "Presentacion",
                    field: "presentacion",
                },
                {
                    label: "descripcion",
                    field: "descripcion",
                },

                {
                    label: "Vender Por",
                    field: "cantidad_tipo",
                },
                {
                    label: "Cantidad minima de venta",
                    field: "cantidad_minima",
                },
                {
                    label: "Cantidad maxima de compra",
                    field: "cantidad_maxima",
                },
                {
                    label: "Cantidad aumentada click",
                    field: "cantidad_aumentada",
                },
                {
                    label: "categoria 1",
                    field: "categoria_1",
                },
                {
                    label: "subcategoria 1",
                    field: "subcategoria_1",
                },
                {
                    label: "categoria 2",
                    field: "categoria_2",
                },
                {
                    label: "subcategoria 2",
                    field: "subcategoria_2",
                },
                {
                    label: "categoria 3",
                    field: "categoria_3",
                },
                {
                    label: "subcategoria 3",
                    field: "subcategoria_3",
                },
                {
                    label: "categoria 4",
                    field: "categoria_4",
                },
                {
                    label: "subcategoria 4",
                    field: "subcategoria_4",
                },
                {
                    label: "etiqueta 1",
                    field: "etiqueta_1",
                },
                {
                    label: "etiqueta 2",
                    field: "etiqueta_2",
                },
                {
                    label: "etiqueta 3",
                    field: "etiqueta_3",
                },
                {
                    label: "Unidad de medida",
                    field: "cantidad_medida",
                },
                {
                    label: "Peso volumetrico",
                    field: "volumen",
                },
                {
                    label: "Peso Kg",
                    field: "peso",
                },
                {
                    label: "Stock disponible",
                    field: "stock_disponible",
                },
                {
                    label: "Costo unitario",
                    field: "costo_unitario",
                },
                {
                    label: "Precio unitario",
                    field: "valor_unitario",
                },
                {
                    label: "Venta sin stock",
                    field: "venta_sin_stock",
                },
                {
                    label: "Stock minimo cedis",
                    field: "stock_minimo_cedis",
                },
                {
                    label: "Stock minimo tienda",
                    field: "stock_maximo_tienda",
                },
                {
                    label: "Impuesto",
                    field: "impuestos",
                },
                {
                    label: "Inventario proveedor",
                    field: "inventario_proveedor",
                },
                {
                    label: "Proveedor",
                    field: "proveedor",
                },

            ]
        },
        fieldsPlantillaUpdateData(){
            return [
                {
                    label: "codigo",
                    field: "codigo",
                },
            ]
        },
        fieldsPlantillaInventario(){
            return[
                {
                    label: "barcode",
                    field: "barcode",
                },
                {
                    label: "sku",
                    field: "sku",
                },
                {
                    label: "cantidad",
                    field: "cantidad",
                },
                {
                    label: "valor",
                    field: "valor",
                },
                {
                    label: "proveedor",
                    field: "proveedor",
                },
                {
                    label: "comentario",
                    field: "comentario",
                },
                {
                    label: "fecha_vencimiento",
                    field: "fecha_vencimiento",
                },
            ]
        }
    },
    methods: {
        toggle(){
            this.$refs.modalOpcionesProductos.toggle();
        },
        setImportarProductos(){

            this.$refs.modalOpcionesProductos.toggle();
            this.$emit('importProductos')
        },
        importUpdateData(){
            this.$refs.modalOpcionesProductos.toggle();
            this.$emit('importUpdateDataProducts');
        },
        descargarCatalogo(){
            this.$refs.modalOpcionesProductos.toggle();
            this.$emit('dowloadCatalogo')
        },
        uploadInventario(){
            this.$refs.modalOpcionesProductos.toggle();
            this.$emit('uploadInventario')
        }
    }
}
</script>

<style lang="scss" scoped>
.box-green{
    width: 54px;
    height: 54px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #29D884;
    cursor:pointer;
}

.box-general{
    width: 54px;
    height: 54px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-general) !important;
    cursor:pointer;
}
</style>
